import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../../../reducers/Slices/productSlice";
import toast from "react-hot-toast";
import { Minus, Plus } from "react-feather";

// import IconWrapper from "../../MicroComponents/IconWrapper";
// import { Heart } from "react-feather";

const ProductCard = ({ product, inCart }) => {
  const dispatch = useDispatch();

  const handleAddtoCart = (product) => {
    dispatch(addToCart({ product }));
  };

  //Cart items cache from redux store
  const cartItemsCache = useSelector((state) => state.product.cartItems);

  const returnProductFromCartCache = () => {
    return cartItemsCache.find((cartItem) => cartItem.id === product.id);
  };

  const cartItem = returnProductFromCartCache();

  const handleQuantityChange = (item, newQuantity) => {
    // const newCartItems = cartItems.map((item) => {
    //   if (item.id === id) {
    //     if (newQuantity < 1) {
    //       console.log(products, "these are the products");
    //       const filteredList = products.filter((product) => product.id !== id);
    //       setProducts(filteredList);
    //     } else if (newQuantity > item.remaining_quantity) {
    //       return { ...item, quantity: item.remaining_quantity };
    //     } else {
    //       return { ...item, quantity: newQuantity };
    //     }
    //   }
    //   return item;
    // });
    // setCartItems(newCartItems);

    if (newQuantity > 0) {
      dispatch(addToCart({ product: item, quantity: newQuantity }));
    } else {
      dispatch(removeFromCart(item.id));
      toast.error("Product removed from cart!");
    }
  };

  return (
    <div className="border-2 border-neutral-200 rounded-[20px] p-[20px] flex flex-col">
      <div className="flex justify-between items-start">
        <div className="flex-grow">
          <h2 className="text-xl font-bold pb-[10px]">{product?.product_name}</h2>
          <p>Brand: {product.brand}</p>
          <p>
            Available Quanitity: <span className="text-neutral-500">{product?.remaining_quantity}</span>
          </p>
          {parseFloat(product?.lowest_rate_batch?.mrp_price) > parseFloat(product?.lowest_rate_batch?.sales_price) ? (
            <p className="flex">
              Price:{" "}
              <span className="text-neutral-500 line-through mx-2 block">₹{product?.lowest_rate_batch?.mrp_price}</span>
              <span className="text-neutral-500">₹{product?.lowest_rate_batch?.sales_price}</span>
            </p>
          ) : (
            <p>
              Price: <span className="text-neutral-500">₹{product?.lowest_rate_batch?.sales_price}</span>
            </p>
          )}
        </div>
        {/* <div className="flex items-center gap-[10px]">
          <IconWrapper>
            <Heart
              className={`z-10 cursor-pointer ${product.isFav ? "text-red-500" : "text-white"}`}
              onClick={onFavToggle}
            />
          </IconWrapper>
        </div> */}
      </div>
      {!inCart && (
        <button
          className={`my-[20px] ${
            product.remaining_quantity !== 0
              ? "bg-black text-white hover:bg-stone-800"
              : "text-red-500 border-red-500 border   -2"
          } rounded-full px-[20px] py-[10px]`}
          disabled={!product.remaining_quantity === 0}
          onClick={() => handleAddtoCart(product)}
        >
          {product.remaining_quantity !== 0 ? "Add to Cart" : "Out of Stock"}
        </button>
      )}
      {inCart && (
        // <button
        //   onClick={() => toast.error("Product is already in cart!")}
        //   className="border-2 border-black text-black hover:bg-black hover:text-white rounded-full px-[20px] py-[10px] my-[20px]"
        // >
        //   Added to Cart
        // </button>
        <div className="flex items-center justify-center mt-[20px] gap-[10px] p-[8px] border-2 border-black rounded-full">
          <button
            onClick={() => handleQuantityChange(cartItem, cartItem.quantity - 1)}
            className="p-[10px] border border-black rounded-full"
          >
            <Minus className="h-[10px] w-[10px] text-black" />
          </button>
          <input
            type="number"
            className="w-12 text-center"
            value={cartItem.quantity}
            onChange={(e) => handleQuantityChange(cartItem, parseInt(e.target.value))}
            min="1"
            max={cartItem.totalAvailable}
          />
          <button
            onClick={() => handleQuantityChange(cartItem, cartItem.quantity + 1)}
            className="p-[10px] border border-black rounded-full"
          >
            <Plus className="h-[10px] w-[10px] text-black" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
