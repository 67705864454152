import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Suspense } from "react";
import { checkTokenExists } from "../../utils/session";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerDetails } from "../../reducers/Slices/userSlice";
import { http } from "../../service/http";

import Home from "../Home";
import LoadScreen from "../LoadScreen";
import UserProfile from "../UserProfile";
import DesktopSideBar from "../SharedComponents/DesktopSideBar";
import Orders from "../Orders/Orders";
import OrderDetailsMobile from "../Orders/OrderDetailsMobile";
import Invoices from "../Invoices";
import NavBar from "../SharedComponents/Navbar";
import Checkout from "../Checkout";
import SearchSection from "../SearchSection";
// import LinkNotFound from "../LinkNotFound";

const Desktopapp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uniqueLinkCode } = useParams();
  // const [linkValid, setLinkValid] = useState(true);

  const showSearchSection = useSelector((state) => state.app.searchSection.show);

  useEffect(() => {
    if (!checkTokenExists() || sessionStorage.getItem("customer") === "null") {
      navigate(`/${uniqueLinkCode}/login`);
    } else {
      handleProfileDetails();
    }
    // eslint-disable-next-line
  }, [uniqueLinkCode]);

  const handleProfileDetails = () => {
    http
      .get(`/online_store/customer/${uniqueLinkCode}/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(setCustomerDetails({ ...res.data.data }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const validateLinkCode = () => {
  //   // http
  //   //   .get(`validate-link/${uniqueLinkCode}/`)
  //   //   .then((res) => {
  //   //     if (res.data.status !== "success") {
  //   //       navigate("/link-not-found"); // Navigate to the error page if the link is invalid
  //   //     } else {
  //   //       handleProfileDetails();
  //   //     }
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //     navigate("/link-not-found");
  //   //   });
  //   if (uniqueLinkCode !== "108e9fde-c9b5-458d-9f8e-ac52e80a0082") {
  //     setLinkValid(false);
  //     return;
  //   }
  //   handleProfileDetails();
  // };

  // if (!linkValid) {
  //   return <LinkNotFound uniqueLinkCode={uniqueLinkCode} />;
  // }

  return (
    <div className="flex w-[100vw]">
      <DesktopSideBar uniqueLinkCode={uniqueLinkCode} />
      <div className="relative h-screen overflow-y-scroll w-full">
        <NavBar uniqueLinkCode={uniqueLinkCode} />
        {showSearchSection && <SearchSection />}
        {!showSearchSection && (
          <div className="md:h-[calc(100vh-80px)]">
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Home uniqueLinkCode={uniqueLinkCode} />
                  </Suspense>
                }
              />
              <Route
                path="/profile"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <UserProfile uniqueLinkCode={uniqueLinkCode} />
                  </Suspense>
                }
              />
              <Route
                path="/orders"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Orders uniqueLinkCode={uniqueLinkCode} />
                  </Suspense>
                }
              />
              <Route
                path="/orders/:id"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <OrderDetailsMobile uniqueLinkCode={uniqueLinkCode} />
                  </Suspense>
                }
              />
              <Route
                path="/invoices"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Invoices uniqueLinkCode={uniqueLinkCode} />
                  </Suspense>
                }
              />
              <Route
                path="/checkout"
                element={
                  <Suspense fallback={<LoadScreen />}>
                    <Checkout uniqueLinkCode={uniqueLinkCode} />
                  </Suspense>
                }
              />
            </Routes>
          </div>
        )}
      </div>
    </div>
  );
};

export default Desktopapp;
