import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import toast from "react-hot-toast";
// import { clearSession } from "../../utils/session";

// export const userDelete = createAsyncThunk("user/userDelete", async (data, extra) => {
//   const res = await http.delete(`/user_profile/backend/delete_account`, {
//     headers: {
//       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data.data;
//   }
// });

//Get Products by products link
export const fetchProductsByUniqueLinkCode = createAsyncThunk(
  "product/fetchProductsByUniqueLinkCode",
  async (data, extra) => {
    const res = await http.get(`/online_store/products/${data.unique_link_code}${data.stringQuery ?? ""}/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
    if (res.data.results.status === "success") {
      return res.data;
    }
  }
);

//Fetch more products
export const fetchMoreProductsByUniqueLinkCode = createAsyncThunk(
  "product/fetchMoreProductsByUniqueLinkCode",
  async (data, extra) => {
    const res = await http.get(data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
    if (res.data.results.status === "success") {
      return res.data;
    }
  }
);

//Get Products by products link
export const fetchSearchProductsByUniqueLinkCode = createAsyncThunk(
  "product/fetchSearchProductsByUniqueLinkCode",
  async (data, extra) => {
    const res = await http.get(`/online_store/products/${data.unique_link_code}${data.stringQuery ?? ""}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
    if (res.data.results.status === "success") {
      return res.data;
    }
  }
);

//Fetch more products
export const fetchMoreSearchProductsByUniqueLinkCode = createAsyncThunk(
  "product/fetchMoreSearchProductsByUniqueLinkCode",
  async (data, extra) => {
    const res = await http.get(data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
    if (res.data.results.status === "success") {
      return res.data;
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState: {
    products: { data: [], next: null },
    searchedProducts: { data: [], next: null },
    cartItems: [],
  },
  reducers: {
    // Add product to cart
    addToCart: (state, action) => {
      const { product, quantity } = action.payload;

      //Existing product index - check if product already exists
      const existingProductIndex = state.cartItems.findIndex((item) => item.id === product.id);

      if (existingProductIndex >= 0) {
        //Check if quantity is not more then remaining quantity
        if (quantity < state.cartItems[existingProductIndex].quantity) {
          //Check if there is quantity provided and if not add to existing quantity
          if (quantity) {
            state.cartItems[existingProductIndex].quantity = quantity;
          } else {
            state.cartItems[existingProductIndex].quantity += 1;
          }
          return;
        } else if (state.cartItems[existingProductIndex].quantity >= product.remaining_quantity) {
          toast.error("Quantity Cannot be more than Available!");
          state.cartItems[existingProductIndex].quantity = product.remaining_quantity;
          return;
        }

        //Check if there is quantity provided and if not add to existing quantity
        if (quantity) {
          state.cartItems[existingProductIndex].quantity = quantity;
        } else {
          state.cartItems[existingProductIndex].quantity += 1;
        }
      } else {
        // Add new product batch to cart with an initial quantity
        state.cartItems.push({
          ...product,
          quantity: 1, // Initial quantity
        });
        toast.success("Product added to cart!");
      }
    },

    // Remove product from cart
    removeFromCart: (state, action) => {
      const id = action.payload;

      state.cartItems = state.cartItems.filter((item) => !(item.id === id));
    },
    //Clear Cart
    clearCart: (state, action) => {
      state.cartItems = [];
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(userDelete.fulfilled, (state, action) => {
    //   clearSession();
    //   window.location.reload();
    // });
    builder.addCase(fetchProductsByUniqueLinkCode.fulfilled, (state, action) => {
      return {
        ...state,
        products: {
          ...state.products,
          data: action.payload.results.data.map((item) => ({
            ...item,
          })),
          next: action.payload.results.next,
        },
      };
    });
    builder.addCase(fetchMoreProductsByUniqueLinkCode.fulfilled, (state, action) => {
      return {
        ...state,
        products: {
          ...state.products,
          data: [...state.products.data, ...action.payload.results.data],
          next: action.payload.results.next,
        },
      };
    });
    builder.addCase(fetchSearchProductsByUniqueLinkCode.fulfilled, (state, action) => {
      return {
        ...state,
        searchedProducts: {
          ...state.searchedProducts,
          data: action.payload.results.data.map((item) => ({
            ...item,
          })),
          next: action.payload.results.next,
        },
      };
    });
    builder.addCase(fetchMoreSearchProductsByUniqueLinkCode.fulfilled, (state, action) => {
      return {
        ...state,
        searchedProducts: {
          ...state.searchedProducts,
          data: [...state.searchedProducts.data, ...action.payload.results.data],
          next: action.payload.results.next,
        },
      };
    });
  },
});

export default productSlice.reducer;
export const { addToCart, removeFromCart, clearCart } = productSlice.actions;
