export const navigationItems = [
  {
    title: "Home",
    imgUrl: "/image/home-icon.svg",
    path: "/",
    cName: "nav-text",
  },
  {
    title: "Orders",
    imgUrl: "/image/home-icon.svg",
    path: "/orders",
    cName: "nav-text",
  },
  {
    title: "Invoices",
    imgUrl: "/image/home-icon.svg",
    path: "/invoices",
    cName: "nav-text",
  },
];
