import { useLocation, Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
    const authData = true;
    const location = useLocation();
    return !authData ? (
        <Navigate to="/" state={{ from: location }} replace />
    ) : (
        <Outlet />
    );
};

export default PublicRoute;
