import React, { useEffect, useState } from "react";
// import "./ComponentStyles.scss";
import InvoiceDataTable from "./InvoiceDataTable";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchInvoices, fetchMoreInvoices } from "../../../reducers/Slices/invoiceSlice";
// import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
// import AddUser from "../../Invoices/AddUser";
// import AddVendor from "../../Purchase/AddVendor";
// import { MenuItem, Select } from "@mui/material";

import IconWrapper from "../../MicroComponents/IconWrapper";
// import { Search } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerInvoices, fetchMoreCustomerInvoices } from "../../../reducers/Slices/invoiceSlice";
// import moment from 'moment';

const InvoiceContainer = () => {
  const dispatch = useDispatch();
  // const [search, setSearch] = useState("");

  const invoices = useSelector((state) => state.invoice.invoices.data);
  const invoicesNext = useSelector((state) => state.invoice.invoices.next);

  // const [filter, setFilter] = useState({ month: "none", date_from: null, date_to: null });
  const [filter, setFilter] = useState({ start_date: null, end_date: null, status: "" });

  // const monthOption = [
  //   { label: "This Month", id: "none" },
  //   { label: "January", id: "1" },
  //   { label: "February", id: "2" },
  //   { label: "March", id: "3" },
  //   { label: "April", id: "4" },
  //   { label: "May", id: "5" },
  //   { label: "June", id: "6" },
  //   { label: "July", id: "7" },
  //   { label: "August", id: "8" },
  //   { label: "September", id: "9" },
  //   { label: "October", id: "10" },
  //   { label: "November", id: "11" },
  //   { label: "December", id: "12" },
  // ];

  //Side effect - fetch customer invoices on first mount and with filter change
  useEffect(() => {
    const params = `?status=${filter.status}&start_date=${filter.start_date || ""}&end_date=${filter.end_date || ""}`;
    dispatch(fetchCustomerInvoices(params));
  }, [dispatch, filter]);

  // let debounceTimer;

  // const handleSearchDebounce = (params) => {
  //   clearTimeout(debounceTimer);
  //   debounceTimer = setTimeout(() => {}, 500);
  // };

  const handleLoadMore = () => {
    if (invoicesNext) {
      dispatch(fetchMoreCustomerInvoices(invoicesNext));
    }
  };

  // const handleSearch = (e) => {
  //   setSearch(e.target.value);
  // };

  const handleFilter = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  return (
    <div className="lg:min-w-[60vw] h-[calc(100vh-150px)]">
      <div className="font-semibold text-xl pb-[20px] pl-[10px]">Your Invoices</div>
      <div className="flex justify-between items-center">
        {/* <div className="w-full mac13:max-w-fit">
          <label for="default-search" className="sr-only">
            Search
          </label>
          <div className="flex gap-[10px] md:gap-[0] items-center">
            <input
              type="search"
              value={search}
              onChange={handleSearch}
              id="default-search"
              className="border-[1px] border-neutral-400 px-[20px] py-[8px] w-full md:w-[350px] rounded-full focus:outline-none"
              placeholder="Search"
              required
            />
            <div className="md:-translate-x-[103%]">
              <IconWrapper>
                <Search className="z-10 text-white" />
              </IconWrapper>
            </div> */}
        {/* <div className="absolute cursor-pointer z-5 inset-y-0 end-0 flex items-center px-3 ">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div> */}
        {/* </div>
        </div> */}
      </div>
      <div className="w-full flex flex-col xs:flex-row gap-[20px] mac13:gap-[0] justify-between items-end xs:items-baseline my-[20px]">
        {/* <div className="flex justify-center max-w-fit items-center">
          <select
            value={filter.month}
            onChange={(e) => setFilter({ ...filter, month: e.target.value })}
            className="bg-white px-[20px] py-[10px]  border border-neutral-400 rounded-full"
          >
            {monthOption.map((i) => (
              <option value={i.id}>{i.label}</option>
            ))}
          </select>
        </div> */}
        <div className="flex justify-center max-w-fit items-center">
          <select
            value={filter.status}
            onChange={(e) => setFilter({ ...filter, status: e.target.value })}
            className="bg-white px-[20px] py-[10px]  border border-neutral-400 rounded-full"
          >
            <option value={""}>All</option>
            <option value={"pending"}>Pending</option>
            <option value={"delivered"}>Delivered</option>
            <option value={"pickup"}>Pickup</option>
            <option value={"cancelled"}>Cancelled</option>
          </select>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-end md:items-center md:[gap-0] gap-[10px] max-w-fit">
          <div className="font-semibold flex items-center justify-end gap-[10px]">
            Between :
            <input
              type="date"
              className="border-2 py-[10px] px-[20px] rounded-full"
              max={filter.start_date}
              onChange={(e) => handleFilter("start_date", e.target.value)}
            />
          </div>
          <div className="font-semibold flex items-center gap-[10px]">
            to :
            <input
              type="date"
              className="border-2 py-[10px] px-[20px] rounded-full"
              min={filter.end_date}
              onChange={(e) => handleFilter("end_date", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div>
        <InvoiceDataTable
          // infoType={infoType}
          next={invoicesNext}
          invoices={invoices}
          fetchMore={handleLoadMore}
        />
      </div>
    </div>
  );
};

export default InvoiceContainer;
