import React, { useState, useRef, useEffect } from "react";
// import "./Navbar.scss"; TODO - delete if nothing breaks
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearSession } from "../../../utils/session";
import { searchSectionFunction, toggleSidebar } from "../../../reducers/Slices/appSlice.js";
import { debounce } from "lodash";
// import { Alert, Snackbar } from "@mui/material";
// import { reset, toggleSidebar } from "../../../reducers/Slices/appSlice";
import profileDefault from "../../../assets/images/profile-default.jpg";
// import { userDelete } from "../../../reducers/Slices/userSlice";

//pHaniver - Remove - Show menu state and function when reference 0

//UI components and imports
import IconWrapper from "../../MicroComponents/IconWrapper.jsx";
import { ShoppingBag, Search, Menu } from "react-feather";
import { localStorgeProfileCache } from "../../../reducers/Slices/userSlice.js";

const NavBar = ({ uniqueLinkCode }) => {
  const data = useSelector((state) => state.user);
  // const alert = useSelector((state) => state.app.alert);
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const navbarContainer = useRef(null);

  useEffect(() => {
    dispatch(localStorgeProfileCache());
  }, []);

  const handleLogout = () => {
    clearSession();
    window.location.reload();
  };
  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  const handleSideBarToggle = () => {
    dispatch(toggleSidebar());
  };

  const debounceHandleSearchValue = debounce((value) => {
    dispatch(searchSectionFunction(value));
  }, 300);

  const handleSearchButton = () => {
    if (navbarContainer.current.offsetWidth < 640) {
      setShowSearch(!showSearch);
      dispatch(searchSectionFunction());
    } else {
      dispatch(searchSectionFunction());
    }
  };

  // TODO - make invoice & orders button if needed
  // TODO - make expandable search button
  // TODO - change icon of nav links to PlusCircle icon from feather library
  // TODO - Add Logo when on tablet screen

  return (
    <>
      <div className="z-50" ref={navbarContainer}>
        {/* <div className="bg-white"> duplicate */}
        <div className="flex-col flex">
          {/* pHaniver - div - Removed md:w-[76.5vw] min-[1124px]:min-w-[84vw] w-screen */}
          <div className="relative border-b-2 border-gray-200 h-[80px] flex items-center">
            <div className="bg-white sm:justify-between w-full items-center px-2 md:px-4 flex">
              <Link
                to={`/${uniqueLinkCode}/`}
                className={`text-[#277494] font-semibold text-2xl ${
                  showSearch ? "hidden" : "block"
                } sm:block xl:hidden`}
                style={{ fontFamily: "Comfortaa, sans-serif" }}
              >
                theprofit<span className="text-orange-500">.AI</span>{" "}
              </Link>

              <div className="flex flex-row ml-auto mr-[10px] sm:m-0">
                <input
                  type="text"
                  className={`border-2 border-neutral-300 rounded-full min-w-[40vw] md:min-w-[30vw] px-[10px] ${
                    showSearch ? "block w-[94vw]" : "hidden"
                  } sm:block`}
                  placeholder="Search Products..."
                  onChange={(e) => debounceHandleSearchValue(e.target.value)}
                />
                <div
                  className={`${showSearch ? "-translate-x-[100%]" : ""} sm:-translate-x-[100%] z-10`}
                  onClick={() => handleSearchButton()}
                >
                  <IconWrapper>
                    <Search className="text-white z-10" />
                  </IconWrapper>
                </div>
              </div>

              <div className={`${showSearch ? "hidden" : "flex"} sm:flex items-center gap-[10px]`}>
                {/* Cart Button */}
                <Link
                  to={`/${uniqueLinkCode}/checkout`}
                  className="rounded-full bg-gray-100 border-[1px] border-gray-200 h-[50px] w-[50px] flex items-center justify-center"
                >
                  <ShoppingBag className="text-black z-10 h-[30px] w-[30px]" />
                </Link>
                {/* profile button */}
                <div
                  className="justify-center items-center flex cursor-pointer"
                  onClick={() => handleShowMenu("settings")}
                >
                  <img
                    src={data?.profile_picture ? data?.profile_picture : profileDefault}
                    className="object-cover btn- h-[50px] w-[50px] rounded-full mr-2 bg-gray-300"
                    alt={`profile of ${data.business_name}`}
                  />
                  <p className="font-bold text-xs lg:text-sm px-2 hidden md:block">{data?.customer_name}</p>
                  <div
                    id="dropdownInformation"
                    onMouseLeave={() => handleShowMenu("settings")}
                    className={`${
                      showMenu !== "settings" ? "hidden" : ""
                    } -ml-16 mt-40 absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
                  >
                    <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                      <li>
                        <Link to={`/${uniqueLinkCode}/profile`} className="block px-4 py-2 hover:bg-gray-100  ">
                          Profile
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="#"
                          className="block px-4 py-2 hover:bg-gray-100"
                          onClick={() => dispatch(userDelete())}
                        >
                          Delete account
                        </Link>
                      </li> */}
                    </ul>
                    <div className="py-2">
                      <Link
                        to="#"
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Sign out
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Menu Button */}
                <button
                  className="bg-gray-100 h-[50px] w-[50px] flex items-center border-[1px] border-gray-200 justify-center rounded-full sm:hidden"
                  onClick={() => handleSideBarToggle()}
                >
                  <Menu className="text-black z-10" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
