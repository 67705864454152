import { addDays } from "date-fns";
import axios from "axios";
import { apiUrl } from "../config/appConfig";

export const setSession = ({ token, customer }) => {
  if (typeof sessionStorage !== "undefined") {
    const { access, refresh } = token;
    sessionStorage.setItem("token", access);
    sessionStorage.setItem("refresh", refresh);
    sessionStorage.setItem("expireAt", addDays(new Date(), 1).getTime()); //add one day as expiry time
    sessionStorage.setItem("customer", customer);
  }
};

export const clearSession = () => {
  if (typeof sessionStorage !== "undefined") {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh");
    sessionStorage.removeItem("expireAt");
    sessionStorage.removeItem("customer");
    sessionStorage.removeItem("profile_image");
  }
};

//Function to refresh access token
export const refreshTokenFn = async () => {
  const refreshToken = sessionStorage.getItem("refresh");
  try {
    const response = await axios.post(`${apiUrl}/user_profile/backend/token/refresh`, { refresh: refreshToken });
    const newToken = response.data.access;
    const newRefreshToken = response.data.refresh || null;

    //Check if there is any customer profile
    if (sessionStorage.getItem("customer") !== null) {
      clearSession();
      return;
    }

    //Check for access token if it dosen't exist logout user
    if (!newToken) {
      clearSession();
      return;
    }

    sessionStorage.setItem("token", newToken);

    //If new refresh token is generated then set it as well
    if (newRefreshToken) {
      sessionStorage.setItem("refresh", newRefreshToken);
    }

    sessionStorage.setItem("expireAt", addDays(new Date(), 1).getTime()); //add one day as expiry time

    return newToken;
  } catch (error) {
    console.error("Some Err Occurred", error);
    clearSession();
    return null;
  }
};

export const checkTokenExists = () => {
  const token = sessionStorage.getItem("token");
  return token !== null;
};
