import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

//fetch orders for particular customer with / without unique-link-code
// export const fetchOnlineOrders = createAsyncThunk("order/fetchOnlineOrders", async (data, extra) => {
//   const res = await http.get(`/online_store/customer/orders${data}`, {
//     headers: {
//       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

//Fetch Customer Invoices
export const fetchCustomerInvoices = createAsyncThunk("invoice/fetchCustomerInvoices", async (data, extra) => {
  const res = await http.get(`/online_store/customer/invoices${data}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch More Customer Invoices
export const fetchMoreCustomerInvoices = createAsyncThunk("invoice/fetchMoreCustomerInvoices", async (data, extra) => {
  const res = await http.get(data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoices: { data: [], next: null },
  },
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(createOnlineOrder.fulfilled, (state, action) => {
    //   return {
    //     ...state.orders,
    //     orders: {
    //       ...state.orders,
    //       data: [action.payload.data, ...state.orders.data],
    //     },
    //   };
    // });
    builder.addCase(fetchCustomerInvoices.fulfilled, (state, action) => {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          data: action.payload.data,
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchMoreCustomerInvoices.fulfilled, (state, action) => {
      return {
        ...state,
        invoices: {
          ...state.orders,
          data: [...state.invoices.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
  },
});

export default invoiceSlice.reducer;
