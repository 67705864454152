import React, { useState } from "react";
import { http } from "../../service/http";

function GSTScreen(props) {
  const { setFormData, formData, setShowGstTab } = props;
  const [toggle, setToggle] = useState(false);

  const handleGSTDetails = async () => {
    try {
      if (toggle) {
        const response = await http.post(
          "user_profile/backend/gst-api/",
          { gst_number: formData.gst_number },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.data) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            ...response.data,
          }));
          setShowGstTab(false);
        }
      } else {
        setShowGstTab(false);
      }
    } catch (error) {
      setShowGstTab(false);
    }
  };

  return (
    <div className="relative">
      <div className="flex items-center justify-between flex-col  p-4 md:p-5 ">
        <h3 className="text-lg font-semibold text-gray-900 ">GST Party?</h3>
        <button
          type="button"
          onClick={() => setToggle(true)}
          className={` ${
            toggle ? "bg-blue-700 text-white" : "bg-white text-gray-900 "
          } border-solid border-gray-300 font-medium rounded-full text-sm px-12 py-2.5 me-2 mt-4  focus:text-white  hover:text-white hover:bg-blue-700  `}
        >
          Yes
        </button>
        <button
          type="button"
          onClick={() => setToggle(false)}
          className={`${
            !toggle ? "bg-blue-700 text-white" : "bg-white text-gray-900 "
          } text-gray-900 border-solid border-gray-300 font-medium rounded-full text-sm px-12 py-2.5 me-2 mt-2    focus:text-white  hover:text-white hover:bg-blue-700 `}
        >
          No
        </button>
      </div>
      <div className={`${toggle ? "" : "hidden"} mt-5`}>
        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 text-center py-1">
          Enter GSTIN
        </label>
        <input
          type="text"
          name="gst_number"
          id="gst_number"
          value={formData.gst_number}
          onChange={(e) => setFormData({ ...formData, gst_number: e.target.value })}
          className="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-2.5 px-5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder="Enter GSTIN"
          required
        />
        <div className="flex justify-center"></div>
      </div>
      <div className="flex w-full justify-center">
        <button
          onClick={handleGSTDetails}
          className="border-black-500 bg-blue-900  dark:hover:text-white dark:hover:bg-blue-700 rounded-full py-2 px-16 mt-6 text-white"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default GSTScreen;
