import React, { useEffect, useState } from "react";
// import "./ComponentStyles.scss";
// import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
// import { fetchInvoices } from "../../../reducers/Slices/invoiceSlice";
import moment from "moment";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../config/appConfig";
// import UpdateInvoice from "./UpdateInvoice";

const InvoiceDataTable = ({ invoices, fetchMore, next }) => {
  // const dispatch = useDispatch();
  //filter invoices based on infoType
  // const customerInvoices = invoices.filter((invoice) => invoice.is_purchase === false);
  // const vendorInvoices = invoices.filter((invoice) => invoice.is_purchase === true);

  const [hasMoreInvoices, setHasMoreInvoices] = useState(() => (next ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (next) {
      setHasMoreInvoices(true);
    } else {
      setHasMoreInvoices(false);
    }
  }, [next]);

  const invoiceDataTableHeaderCustomer = [
    {
      id: 0,
      title: "Date",
      key: "date",
    },
    {
      id: 1,
      title: "Invoice No.",
      key: "invoiceNumber",
    },
    {
      id: 1,
      title: "Order Id",
      key: "orderId",
    },
    {
      id: 2,
      title: "Amount",
      key: "amount",
    },
    {
      id: 3,
      title: "Status",
      key: "status",
    },
    {
      id: 4,
      title: "invoice PDF",
      key: "download",
    },
  ];

  return (
    <div>
      <InfiniteScroll
        dataLength={invoices.length}
        next={fetchMore}
        hasMore={hasMoreInvoices}
        height={"50vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
      >
        <table className="w-full">
          <thead>
            <tr>
              {invoiceDataTableHeaderCustomer.map((item, index) => (
                <th
                  key={index}
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                  scope="col"
                >
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          {invoices?.length > 0 ? (
            <tbody>
              {invoices.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    {moment(item?.invoice?.order_date_time).format("LLL")}
                  </td>
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    {item?.invoice?.invoice_counter}
                  </td>
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    {item?.id}
                  </td>
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    Rs {item?.invoice?.grand_total}
                  </td>
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    {item?.invoice.payment_type}
                  </td>
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-blue-500 font-bold bg-white  border-[1px] border-neutral-200">
                    <Link to={`${apiUrl}${item?.invoice?.Invoice_pdf}`} target="_blank" rel="noopener noreferrer">
                      <button className="capitalize">download</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <td colSpan={invoiceDataTableHeaderCustomer.length}>
              <h2 className="text-center">No Invoice Found</h2>
            </td>
          )}
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default InvoiceDataTable;
