import { combineReducers } from "redux";
import authSlice from "./Slices/authSlice";
import appSlice from "./Slices/appSlice";
import userSlice from "./Slices/userSlice";
import productSlice from "./Slices/productSlice";
import orderSlice from "./Slices/orderSlice";
import invoiceSlice from "./Slices/invoiceSlice";

const appReducer = combineReducers({
  auth: authSlice,
  app: appSlice,
  user: userSlice,
  product: productSlice,
  order: orderSlice,
  invoice: invoiceSlice,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
