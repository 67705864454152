import React from "react";

function LinkNotFound({ uniqueLinkCode }) {
  if (uniqueLinkCode) {
    return (
      <div className="flex flex-col items-center justify-center h-[100vh]">
        <img src="/404.jpg" alt="Link Not Found" className="h-[60vh] -mt-[10vh]" />
        <span className="text-2xl font-semibold text-[#FF725D]">
          Sorry, {`www.store.theprofit.ai/${uniqueLinkCode}`} you provided seems expired or incorrect.
        </span>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-[100vh]">
        <img src="/404.jpg" alt="Link Not Found" className="h-[60vh] -mt-[10vh]" />
        <span className="text-2xl font-semibold text-[#FF725D] max-w-[50vw] text-center">
          Sorry, link you followed seems incorrect. It's missing Unique Code.
        </span>
      </div>
    );
  }
}

export default LinkNotFound;
