import React from 'react'

function iconWrapper({children, notification = false, theme="light"}) {
  const themeStyle = theme === 'light' ? 'before:bg-black' : 'before:bg-white';
  
  // Style of div surrounding Icon
  const divStyle = `relative flex h-[40px] w-[40px] items-center justify-center`;
  
  // Surrounding Circle
  const beforeStyle = `before:content-[''] before:w-[40px] before:h-[40px] before:rounded-full before:absolute hover:before:bg-neutral-600 before:top-[50%] before:left-[50%] before:translate-x-[-50%] before:translate-y-[-50%]`
  
  // Notification Dot - Apply only if notification prop provided
  const afterStyle = notification ? `after:content-[''] after:h-[12px] after:w-[12px] after:rounded-full after:absolute after:bg-red-600 after:top-0 after:right-0` : '';
 
  return (
    <div className={`${divStyle} ${beforeStyle} ${afterStyle} ${themeStyle}`}>
        {children}
    </div>
 )
}

export default iconWrapper