import React, { useEffect, useState } from "react";

import { Minus, Plus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createOnlineOrder } from "../../reducers/Slices/orderSlice";
import toast from "react-hot-toast";
import { addToCart, clearCart, removeFromCart } from "../../reducers/Slices/productSlice";

// const formatTime = (time) => {
//   const [hour, minute] = time.split(":").map(Number);
//   const period = hour >= 12 ? "PM" : "AM";
//   const formattedHour = hour % 12 || 12;
//   return `${formattedHour}:${minute < 10 ? `0${minute}` : minute} ${period}`;
// };

const currentDate = () => {
  const today = new Date();

  // Format the date as YYYY-MM-DD
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(today.getDate()).padStart(2, "0");

  // Set the formatted date as the default value
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const CheckoutPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [cartEmpty, setCartEmpty] = useState(true);
  const [deliveryType, setDeliveryType] = useState("pickup");
  // const [paymentMethod, setPaymentMethod] = useState("cash");
  const [slot, setSlot] = useState({ date: currentDate(), time: "00:00:00" });

  //Cart items cache from redux store
  const cartItemsCache = useSelector((state) => state.product.cartItems);

  const { uniqueLinkCode } = useParams();

  //Set Products on First Render
  useEffect(() => {
    // const productsList = [
    //   {
    //     id: 86,
    //     product_name: "jgjhgj",
    //     brand: "hjhkjh",
    //     remaining_quantity: 12,
    //     lowest_rate_batch: {
    //       id: 227,
    //       batch_number: "jghgf",
    //       bar_code_number: null,
    //       rate: null,
    //       sales_price: "1000",
    //       mrp_price: "1000.00",
    //       total_quantity: 12,
    //       remaining_quantity: 12,
    //       expiry_date: "0002-12-12T00:00:00",
    //     },
    //   },
    //   {
    //     id: 87,
    //     product_name: "Majina",
    //     brand: "Majin",
    //     remaining_quantity: 10,
    //     lowest_rate_batch: {
    //       id: 228,
    //       batch_number: "BAG200",
    //       bar_code_number: null,
    //       rate: null,
    //       sales_price: "800",
    //       mrp_price: "1000.00",
    //       total_quantity: 10,
    //       remaining_quantity: 10,
    //       expiry_date: "2024-08-19T00:00:00",
    //     },
    //   },
    // ];
    // const productsList = [
    //   { id: 1, name: "Product 1", brand: "Brand A", price: 100, totalAvailable: 10, isFav: false },
    //   { id: 3, name: "Product 3", brand: "Brand C", price: 150, totalAvailable: 8, isFav: false },
    //   { id: 4, name: "Product 4", brand: "Brand D", price: 250, totalAvailable: 12, isFav: false },
    //   { id: 6, name: "Product 6", brand: "Brand F", price: 50, totalAvailable: 1, isFav: false },
    // ];
    setProducts(cartItemsCache);
  }, [cartItemsCache]);

  //  Based on products set cart Items
  useEffect(() => {
    if (products.length <= 0) {
      setCartEmpty(true);
      return;
    }
    setCartEmpty(false);
    setCartItems(products);
  }, [products]);

  const handleQuantityChange = (item, newQuantity) => {
    // const newCartItems = cartItems.map((item) => {
    //   if (item.id === id) {
    //     if (newQuantity < 1) {
    //       console.log(products, "these are the products");
    //       const filteredList = products.filter((product) => product.id !== id);
    //       setProducts(filteredList);
    //     } else if (newQuantity > item.remaining_quantity) {
    //       return { ...item, quantity: item.remaining_quantity };
    //     } else {
    //       return { ...item, quantity: newQuantity };
    //     }
    //   }
    //   return item;
    // });
    // setCartItems(newCartItems);

    if (newQuantity > 0) {
      dispatch(addToCart({ product: item, quantity: newQuantity }));
    } else {
      dispatch(removeFromCart(item.id));
    }
  };

  // const handleOrderButton = () => {
  //   alert("handleOrderButton was clicked");
  //   console.log({
  //     cartItems,
  //     deliveryType,
  //     paymentMethod,
  //     slot: {
  //       from: formatTime(slot.from),
  //       to: formatTime(slot.to),
  //     },
  //   });
  // };

  const handleOrderButton = () => {
    const productsData = cartItems.map((item) => {
      return { product_id: item.id, batch_id: item.lowest_rate_batch.id, quantity: item.quantity };
    });

    const orderData = {
      unique_link_code: uniqueLinkCode,
      order_type: deliveryType,
      time_slot: `${slot.date} ${slot.time}`,
      products: productsData,
    };

    dispatch(createOnlineOrder(orderData))
      .then((action) => {
        if (action.payload) {
          toast.success("order created successfully");
          dispatch(clearCart());
          navigate(`/${uniqueLinkCode}/`);
        } else {
          toast.error("order creation failed");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const calculateTotal = () => {
    if (cartEmpty) {
      return 0;
    }
    return cartItems.reduce((total, item) => total + parseFloat(item.lowest_rate_batch.sales_price) * item.quantity, 0);
  };

  return (
    <div className="h-[83vh]">
      <h1 className="text-2xl font-semibold border-b-2 p-[20px] flex items-center]">Checkout</h1>

      <div className="flex flex-wrap md:h-[83vh]">
        {/* Left Section - Settings */}
        <div className="flex flex-col gap-[10px] w-full md:w-2/4 p-[20px] border-b-2 md:border-b-0 md:border-r-2  border-neutral-200 mb-4 sm:mb-0">
          <h2 className="text-xl font-semibold pb-[20px]">Order Settings</h2>
          <div className="px-[10px] py-[20px] border-2 rounded-[20px]">
            <div className="mb-4">
              <h3 className="text-lg font-semibold ml-[10px]">Delivery Type</h3>
              <select
                className="border-2 px-[10px] py-[10px] mt-[10px] rounded p-2 w-full bg-white"
                value={deliveryType}
                onChange={(e) => setDeliveryType(e.target.value)}
              >
                <option value="delivery">Delivery</option>
                <option value="pickup">Pickup</option>
              </select>
            </div>
            {/*
            <div className="mb-4">
              <h3 className="text-lg font-bold mb-2">Payment Method</h3>
              <select
                className="border rounded p-2 w-full"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value="cash">Cash on Delivery</option>
                <option value="upi">UPI</option>
              </select>
            </div> */}
            <div className="mb-4 flex flex-col gap-[10px]">
              <h3 className="text-lg pb-[10px] font-semibold ml-[10px]">Select Slot: </h3>
              <div className="flex items-center space-x-2 ml-[10px]">
                <span className="text-lg text-neutral-500">date</span>
                <input
                  aria-label="slot date"
                  type="date"
                  min="07:00:00"
                  max="18:00:00"
                  className="border-2 border-black px-[10px] py-[5px] rounded-full"
                  value={slot.date}
                  onChange={(e) => setSlot({ ...slot, date: e.target.value })}
                />
                <span className="text-lg text-neutral-500">time</span>
                <input
                  aria-label="slot time"
                  type="time"
                  min="07:00:00"
                  max="18:00:00"
                  className="border-2 border-black px-[10px] py-[5px] rounded-full"
                  value={slot.time}
                  step="1"
                  onChange={(e) => setSlot({ ...slot, time: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Right Section - Products and Total Amount */}
        <div className="w-full md:w-2/4 p-[20px]">
          <h2 className="text-xl font-semibold pb-[20px]">Products</h2>
          {cartEmpty && (
            <div className="py-[20px]">
              <span className="text-lg text-neutral-500">Cart is Empty! Add products to continue.</span>
            </div>
          )}
          {!cartEmpty && (
            <ul className="pb-[20px] flex flex-col gap-[10px] max-h-[60vh] overflow-y-scroll">
              {cartItems.map((item) => (
                <li
                  key={item.id}
                  className="flex justify-between items-center border-2 border-neutral-200 p-[10px] rounded-[20px]"
                >
                  <div>
                    <p className="font-bold">{item.product_name}</p>
                    <p>Brand: {item.brand}</p>
                    <p>Available Quantity: {item.remaining_quantity}</p>
                    <p>Price: ₹{item?.lowest_rate_batch?.sales_price}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleQuantityChange(item, item.quantity - 1)}
                      className="p-[10px] border border-black rounded-full"
                    >
                      <Minus className="h-[10px] w-[10px] text-black" />
                    </button>
                    <input
                      type="number"
                      className="w-12 text-center"
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(item, parseInt(e.target.value))}
                      min="1"
                      max={item.totalAvailable}
                    />
                    <button
                      onClick={() => handleQuantityChange(item, item.quantity + 1)}
                      className="p-[10px] border border-black rounded-full"
                    >
                      <Plus className="h-[10px] w-[10px] text-black" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}

          <div className="flex flex-col gap-[10px] md:flex-row md:gap-[0] justify-between border-y-2 border-neutral-200 py-[20px] items-center">
            {/* Total Amount */}
            <h3 className="text-xl font-bold mb-2 text-nowrap">
              Total Amount: <span className="text-neutral-500 font-medium">₹{calculateTotal()}</span>
            </h3>

            {/* Order Button */}
            <button
              onClick={handleOrderButton}
              className={`text-white rounded-full max-w-[200px] px-4 py-2 w-full ${
                cartEmpty ? "bg-black/45" : "bg-black"
              }`}
              disabled={cartEmpty}
            >
              Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
