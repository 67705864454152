import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { changeDateFormat } from "../../../utils/dateFormat";

//UI components
import IconWrapper from "../../MicroComponents/IconWrapper";
import DetailContainer from "../../MicroComponents/DetailContainer";
import { Search, Filter } from "react-feather";
import DateRangeSelector from "../OnlineOrderComponents/DateRangeSelector";
// import Timer from "../OnlineOrderComponents/Timer";
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreOnlineOrders, fetchOnlineOrders, getOrderDetailsByID } from "../../../reducers/Slices/orderSlice";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";

function OnlineOrders() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedOrder, setSelectedOrder] = useState({});
  // const [timerEnd, setTimerEnd] = useState(true);

  const orderDetails = useSelector((state) => state.order.orderDetails);

  function convertTimestamp(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Options for formatting the date and time
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, // For 12-hour format, set to false for 24-hour format
    };

    // Convert the date to a string with the specified options
    return date.toLocaleString("en-US", options);
  }

  //Get screenSize
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showMenu, setShowMenu] = useState(false);

  //handle Filter button Menu
  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  //Base string for fetchCustomerAndVendor Reducer API
  const [stringQuery, setStringQuery] = useState("/");

  //Days filter
  const [daysFilter, setDaysFilter] = useState("last_7_days");

  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //from date and to date
  const [fromDate, setFromDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - 7);
    return changeDateFormat(pastDate);
  });

  const [toDate, setToDate] = useState(() => {
    return changeDateFormat(new Date());
  });

  //Order search functionality
  useEffect(() => {
    setStringQuery(() => {
      let initialString = "";

      //Guard Clause
      if (searchTerm.length === 0) {
        return `?status=${filter}&start_date=${fromDate}&end_date=${toDate}`;
      }

      //Setting search term
      if (searchTerm.length > 0) {
        initialString += `?search=${searchTerm}`;
      }

      //Setting filter string
      if (searchTerm.length === 0) {
        initialString += `?status=${filter}`;
      } else if (filter.length > 0) {
        initialString += `&status=${filter}`;
      }

      return initialString + `&start_date=${fromDate}&end_date=${toDate}`;
    });
  }, [filter, searchTerm, fromDate, toDate]);

  //Debounced SearchTerm
  const debouncedSetSearchTerm = debounce((term) => {
    setSearchTerm(term);
  }, 300);

  //Search using API
  useEffect(() => {
    dispatch(fetchOnlineOrders(stringQuery))
      .then((action) => {
        if (action.payload) {
        }
      })
      .catch((err) => {
        toast.error("Failed to fetch products!");
      });
  }, [stringQuery, dispatch]);

  //side effect based on date range change - custom date setFromDate and setToDate
  useEffect(() => {
    setFromDate(changeDateFormat(dateRange[0].startDate));
    setToDate(changeDateFormat(dateRange[0].endDate));
  }, [dateRange]);

  //side effect to set date based on filter selected
  useEffect(() => {
    const today = new Date();
    const pastDate = new Date();

    if (daysFilter === "custom") {
      return;
    } else if (daysFilter === "last_7_days") {
      pastDate.setDate(today.getDate() - 7);
    } else if (daysFilter === "last_30_days") {
      pastDate.setDate(today.getDate() - 30);
    }

    setFromDate(changeDateFormat(pastDate));
    setToDate(changeDateFormat(today));
  }, [daysFilter]);

  //Fake order data
  // const orders = [
  //   {
  //     id: 1,
  //     order_details: {
  //       order_status: "Pending",
  //       ordered_on: "20/10/2024",
  //       total_amount: "₹20,000",
  //       type: "Delivery",
  //       store_id: 1,
  //       name: "Imagine LTD",
  //       contact_number: "+91-8290194551",
  //       address: "357 Maplewood Drive, Greenfield, IL 62704, USA",
  //       payment_method: "Cash on Delivery",
  //       slot_selected: "7 AM to 7 PM",
  //       timerStatus: true,
  //       ordered_products: [
  //         {
  //           product_name: "Oneplus 6T",
  //           product_brand: "Oneplus",
  //           product_batch: "IQT28M",
  //           quantity: 10,
  //         },
  //         {
  //           product_name: "Iphone 13",
  //           product_brand: "Apple",
  //           product_batch: "PMT28M",
  //           quantity: 24,
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 2,
  //     order_details: {
  //       order_status: "Fulfilled",
  //       ordered_on: "20/10/2024",
  //       total_amount: "₹20,000",
  //       type: "Pick Up",
  //       store_id: 10,
  //       name: "Moritus LTD",
  //       contact_number: "+91-8290194551",
  //       address: "357 Maplewood Drive, Greenfield, IL 62704, USA",
  //       payment_method: "UPI",
  //       slot_selected: "7 AM to 7 PM",
  //       timerStatus: false,
  //       ordered_products: [
  //         {
  //           product_name: "Oneplus 6T",
  //           product_brand: "Oneplus",
  //           product_batch: "IQT28M",
  //           quantity: 10,
  //         },
  //         {
  //           product_name: "Iphone 13",
  //           product_brand: "Apple",
  //           product_batch: "PMT28M",
  //           quantity: 24,
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 3,
  //     order_details: {
  //       order_status: "Cancelled",
  //       ordered_on: "20/10/2024",
  //       total_amount: "₹20,000",
  //       type: "Pick Up",
  //       store_id: 2,
  //       name: "Malobar LTD",
  //       contact_number: "+91-8230194551",
  //       address: "357 Maplewood Drive, Greenfield, IL 62704, USA",
  //       payment_method: "Cash On Delivery",
  //       slot_selected: "6 AM to 4 PM",
  //       timerStatus: true,
  //       ordered_products: [
  //         {
  //           product_name: "Oneplus 6T",
  //           product_brand: "Oneplus",
  //           product_batch: "IQT28M",
  //           quantity: 10,
  //         },
  //         {
  //           product_name: "Iphone 13",
  //           product_brand: "Apple",
  //           product_batch: "PMT28M",
  //           quantity: 24,
  //         },
  //       ],
  //     },
  //   },
  // ];

  // const orders = [
  //   {
  //     id: 1,
  //     order_type: "pickup",
  //     total_amount: "0.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  //   {
  //     id: 2,
  //     order_type: "pickup",
  //     total_amount: "28000.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  //   {
  //     id: 3,
  //     order_type: "pickup",
  //     total_amount: "28000.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  //   {
  //     id: 4,
  //     order_type: "pickup",
  //     total_amount: "140000.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  //   {
  //     id: 5,
  //     order_type: "pickup",
  //     total_amount: "140000.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  //   {
  //     id: 7,
  //     order_type: "pickup",
  //     total_amount: "140000.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  //   {
  //     id: 8,
  //     order_type: "pickup",
  //     total_amount: "140000.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  //   {
  //     id: 11,
  //     order_type: "pickup",
  //     total_amount: "140000.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  //   {
  //     id: 12,
  //     order_type: "pickup",
  //     total_amount: "140000.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  //   {
  //     id: 13,
  //     order_type: "pickup",
  //     total_amount: "140000.00",
  //     status: "Pending",
  //     time_slot: "2024-08-25",
  //     customer: 18,
  //   },
  // ];

  const orders = useSelector((state) => state.order.orders.data);
  const ordersNext = useSelector((state) => state.order.orders.next);

  const [hasMoreOrders, setHasMoreOrders] = useState(() => (ordersNext ? true : false));

  const fetchMoreOrders = () => {
    if (ordersNext) {
      dispatch(fetchMoreOnlineOrders(ordersNext));
    }
  };
  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (ordersNext) {
      setHasMoreOrders(true);
    } else {
      setHasMoreOrders(false);
    }
  }, [ordersNext]);

  //Side effect to select first order on render
  useEffect(() => {
    setSelectedOrder(orders[0]);
  }, [orders]);

  useEffect(() => {
    if (!selectedOrder?.id) {
      return;
    }
    // setTimerEnd(selectedOrder?.order_details?.timerStatus);
    dispatch(getOrderDetailsByID(selectedOrder?.id));
  }, [selectedOrder, dispatch]);

  const orderStatusColor = (orderStatus) => {
    if (orderStatus === "Fulfilled" || orderStatus === "fulfilled") {
      return "text-green-500";
    } else if (orderStatus === "Cancelled" || orderStatus === "cancelled") {
      return "text-neutral-500";
    } else if (orderStatus === "Pending" || orderStatus === "pending") {
      return "text-red-500";
    } else if (orderStatus === "Pickup" || orderStatus === "pickup") {
      return "text-green-500";
    } else if (orderStatus === "Delivered" || orderStatus === "delivered") {
      return "text-green-500";
    }
  };

  const handleMobileOrderDetails = (id) => {
    if (screenSize.width < 1200) {
      navigate(`${id}`);
    }
  };

  return (
    <div className="flex">
      {/* Order list, search and filter section*/}
      <div className="w-full mac13:w-1/3 border-r-2 border-neutral-200 h-[91.3vh]">
        {/* Order Search and Order Filter */}
        <div className="border-b-2 border-neutral-200 flex p-[15px] gap-[5px]">
          {/* Search Bar and Search Button */}
          <div className="w-full flex">
            <input
              type="text"
              className="border-2 rounded-full h-[44px] w-full p-[10px]"
              placeholder="Search Orders, Products"
              onChange={(e) => debouncedSetSearchTerm(e.target.value)}
            />
            <button className="h-[44px] -ml-[42px]">
              <IconWrapper>
                <Search className="text-white z-10" />
              </IconWrapper>
            </button>
          </div>

          {/* Filter Button and Functionality */}
          <div className="relative">
            <button className="h-[44px]" onClick={() => handleShowMenu("settings")}>
              <IconWrapper>
                <Filter className="text-white z-10" />
              </IconWrapper>
            </button>
            <div
              id="dropdownInformation"
              onMouseLeave={() => handleShowMenu("settings")}
              className={`${
                showMenu !== "settings" ? "hidden" : ""
              } translate-x-[-50%] left-[50%] mt-[10px] absolute z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
            >
              <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                <li>
                  <button onClick={() => setFilter("")} className="text-lg py-2 w-full border-b-2 border-neutral-200">
                    All
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setFilter("Pending")}
                    className="text-lg py-2 w-full border-b-2 border-neutral-200"
                  >
                    Pending Only
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setFilter("Fulfilled")}
                    className="text-lg pt-2 border-b-2 w-full border-neutral-200"
                  >
                    Fulfilled Only
                  </button>
                </li>
                <li>
                  <button onClick={() => setFilter("Cancelled")} className="text-lg pt-2 w-full">
                    Cancelled Only
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Order List section*/}
        <div>
          {/* Days Filter */}
          <div className="flex px-[10px] py-[20px] justify-between">
            <label htmlFor="days_filter" className="sr-only">
              Select Date
            </label>
            <select
              name="days_filter"
              id="days_filter"
              value={daysFilter}
              onChange={(e) => setDaysFilter(e.target.value)}
              className="px-[20px] py-[10px] bg-white border-[1px] border-black rounded-full max-w-fit"
            >
              <option value="last_7_days">Last 7 Days</option>
              <option value="last_30_days">Last 30 Days</option>
              <option value="custom">Custom</option>
            </select>

            {daysFilter === "custom" && <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />}
          </div>
          {/* Order List */}
          <InfiniteScroll
            dataLength={orders.length}
            next={fetchMoreOrders}
            hasMore={hasMoreOrders}
            height={"70vh"}
            loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
          >
            <div className="flex flex-col px-[10px] gap-[10px]">
              {orders?.map((order) => (
                <div
                  key={order?.id}
                  className="flex flex-col 2xl:flex-row 2xl:justify-between 2xl:items-center border-2 border-neutral-200 rounded-[20px] p-[20px]"
                  onClick={() => {
                    setSelectedOrder(order);
                    handleMobileOrderDetails(order?.id);
                  }}
                >
                  <h3 className="text-xl">Order {order?.id}</h3>
                  <div className="flex flex-col 2xl:items-end">
                    <span>
                      Order Status:{" "}
                      <span className={`${orderStatusColor(order?.status)} capitalize`}>{order?.status}</span>
                    </span>
                    {/* <span>
                    Ordered On: <span className="text-neutral-500">{order?.order_details?.ordered_on}</span>
                  </span>*/}
                    <span>
                      Time Slot: <span className="text-neutral-500">{convertTimestamp(order?.time_slot)}</span>
                    </span>
                    <span>
                      Total Amount: <span className="text-neutral-500">{order?.total_amount}</span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      {/* selected order and order details section */}
      <div className="hidden mac13:block mac13:w-1/3 border-r-2 border-neutral-200 h-[91.3vh]">
        {/* Selected order description */}
        <div className="h-[8.3vh] border-b-2 border-neutral-200 flex justify-between px-[20px] items-center">
          <h3 className="text-2xl text-neutral-500">{`Order ${selectedOrder?.id}`}</h3>
          {/* {!timerEnd && ( */}
          <span className="flex gap-[10px] items-center">
            Status:
            <span className={`${orderStatusColor(selectedOrder?.status)} capitalize`}>{selectedOrder?.status}</span>
          </span>
          {/* )} */}
          {/* {timerEnd && (
            <button
              className="text-red-500 border rounded-full border-red-500 px-[20px] py-[10px]"
              onClick={() => alert("cancel order was clicked!")}
            >
              Cancel Order -{" "}
              <Timer
                orderId={selectedOrder?.id}
                setTimerEnd={setTimerEnd}
                timerStatus={selectedOrder?.order_details?.timerStatus}
              />
            </button>
          )} */}
        </div>
        {/* Order details section */}
        <div>
          <span className="text-neutral-500 py-[20px] text-center w-full block">Order Details</span>
          <div className="flex flex-col px-[10px] gap-[10px]">
            <DetailContainer title="Order type" value={orderDetails?.order_type} />
            {/* <DetailContainer title="Contact Number" value={selectedOrder?.order_details?.contact_number} /> */}
            {/* <DetailContainer title="Address" value={selectedOrder?.order_details?.address} /> */}
            {selectedOrder?.status === "Fulfilled" && (
              <DetailContainer title="Payment Method" value={orderDetails?.payment_option?.payment_method} />
            )}
            <DetailContainer title="Total Amount" value={orderDetails?.total_amount} />
            {selectedOrder?.status === "Fulfilled" && (
              <DetailContainer title="Ordered On" value={convertTimestamp(orderDetails?.invoice?.order_date_time)} />
            )}
            <DetailContainer title="Slot Selected" value={convertTimestamp(orderDetails?.time_slot)} />
          </div>
        </div>
      </div>
      {/* ordered products sections */}
      <div className="hidden mac13:block mac13:w-1/3 h-[91.3vh]">
        {/* ordered products */}
        <div className="h-[8.3vh] border-b-2 border-neutral-200 text-xl flex items-center justify-center gap-[10px]">
          Total Ordered Products:
          <span className="text-neutral-500">{orderDetails?.items?.length}</span>
        </div>
        {/* ordered products list */}
        <div className="px-[10px] flex flex-col gap-[10px]">
          {orderDetails?.items?.length > 0 &&
            orderDetails?.items?.map((item, index) => (
              <div
                key={`${item?.product?.product_name}${index}`}
                className="flex flex-col border-2 border-neutral-200 rounded-[20px] p-[20px] first:mt-[20px]"
              >
                <span className="text-xl block pb-[10px]">{item?.product?.product_name}</span>
                <span>
                  Brand: <span className="text-neutral-500">{item?.product?.brand}</span>
                </span>
                {/* <span>
                  Batch: <span className="text-neutral-500">{item?.product_batch}</span>
                </span> */}
                <span>
                  Quantity: <span className="text-neutral-500">{item?.quantity}</span>
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default OnlineOrders;
