import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

//Create order
export const createOnlineOrder = createAsyncThunk("order/createOnlineOrder", async (data, extra) => {
  const res = await http.post(`/online_store/customer/order/`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//fetch orders for particular customer with / without unique-link-code
export const fetchOnlineOrders = createAsyncThunk("order/fetchOnlineOrders", async (data, extra) => {
  const res = await http.get(`/online_store/customer/orders${data}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch more orders - react infinity scroll
export const fetchMoreOnlineOrders = createAsyncThunk("order/fetchMoreOnlineOrders", async (data, extra) => {
  const res = await http.get(data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Get order details by ID
export const getOrderDetailsByID = createAsyncThunk("order/getOrderDetailsByID", async (data, extra) => {
  const res = await http.get(`/online_store/customer/order/${data}/`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: { data: [], next: null },
    orderDetails: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createOnlineOrder.fulfilled, (state, action) => {
      return {
        ...state.orders,
        orders: {
          ...state.orders,
          data: [action.payload.data, ...state.orders.data],
        },
      };
    });
    builder.addCase(fetchOnlineOrders.fulfilled, (state, action) => {
      return {
        ...state,
        orders: {
          ...state.orders,
          data: action.payload.data,
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchMoreOnlineOrders.fulfilled, (state, action) => {
      return {
        ...state,
        orders: {
          ...state.orders,
          data: [...state.orders.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(getOrderDetailsByID.fulfilled, (state, action) => {
      return {
        ...state,
        orderDetails: action.payload,
      };
    });
  },
});

export default orderSlice.reducer;
