// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { http } from "../../service/http";
import { createSlice } from "@reduxjs/toolkit";

// //pHaniver - TODO - delete toggle and menu related code when reference 0
// export const fetchDashboard = createAsyncThunk("app/fetchDashboard", async (data, extra) => {
//   const res = await http.get("/user_profile/backend/dashboard", {
//     headers: {
//       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     console.log(res.data);
//     return res.data.data;
//   }
// });

// //Global Search Functionality
// export const fetchGlobalSearchData = createAsyncThunk("app/globalSearch", async (data, extra) => {
//   const res = await http.get(`/user_profile/backend/global_search?query=${data}`, {
//     headers: {
//       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data.data;
//   }
// });

export const appSlice = createSlice(
  {
    name: "app",
    initialState: {
      sidebar: { show: false },
      searchSection: { show: false, value: "" },
    },
    reducers: {
      toggleSidebar: (state, action) => {
        state.sidebar.show = !state.sidebar.show;
      },
      searchSectionFunction: (state, action) => {
        console.log("action payload", action.payload, typeof action.payload);
        if (typeof action.payload === "string") {
          if (action.payload.length === 0) {
            return { ...state, searchSection: { show: false, value: "" } };
          }
          return { ...state, searchSection: { show: true, value: action.payload } };
        } else if (typeof action.payload === "boolean") {
          return { ...state, searchSection: { ...state.searchSection, show: action.payload } };
        } else {
          return { ...state, searchSection: { ...state.searchSection, show: !state.searchSection.show } };
        }
      },
    },
  }
  //   extraReducers: (builder) => {
  //     builder.addCase(fetchDashboard.fulfilled, (state, action) => {
  //       return { ...state, dashboard: action.payload };
  //     });
  //     builder.addCase(fetchGlobalSearchData.fulfilled, (state, action) => {
  //       console.log(action.payload, "this is action payload");
  //       const { products, customers, vendors } = action.payload;
  //       return {
  //         ...state,
  //         global_search: {
  //           products: products || [],
  //           customers: customers || [],
  //           vendors: vendors || [],
  //         },
  //       };
  //     });
  //   },}
);

export default appSlice.reducer;
// export const { toggleAlert, reset, toggleSidebar, toggleGlobalSearch } = appSlice.actions;
export const { toggleSidebar, searchSectionFunction } = appSlice.actions;
