import React from "react";

function DetailContainer({ title, value }) {
  return (
    <div className="bg-neutral-100 rounded-[10px] py-[8px] px-[15px] w-full">
      <h3 className="text-xs text-neutral-500">{title}</h3>
      <span>{value}</span>
    </div>
  );
}

export default DetailContainer;
