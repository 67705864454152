import React, { useEffect, useState } from "react";
import ProductCard from "./HomeComponents/ProductCard";
import {
  addToCart,
  fetchMoreProductsByUniqueLinkCode,
  fetchProductsByUniqueLinkCode,
  removeFromCart,
} from "../../reducers/Slices/productSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

// const productsDummy = [
//   { id: 1, name: "Product 1", brand: "Brand A", price: 100, totalAvailable: 10, isFav: false },
//   { id: 2, name: "Product 2", brand: "Brand B", price: 200, totalAvailable: 0, isFav: false },
//   { id: 3, name: "Product 3", brand: "Brand C", price: 150, totalAvailable: 8, isFav: false },
//   { id: 4, name: "Product 4", brand: "Brand D", price: 250, totalAvailable: 12, isFav: false },
//   { id: 5, name: "Product 5", brand: "Brand E", price: 300, totalAvailable: 0, isFav: false },
//   { id: 6, name: "Product 6", brand: "Brand F", price: 50, totalAvailable: 20, isFav: false },
// ];

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortType, setSortType] = useState("all");
  const [productList, setProductList] = useState([]);
  const { uniqueLinkCode } = useParams();

  const products = useSelector((state) => state.product.products.data);
  const productsNext = useSelector((state) => state.product.products.next);

  //Cart items cache from redux store
  const cartItemsCache = useSelector((state) => state.product.cartItems);

  const [hasMoreProducts, setHasMoreProducts] = useState(() => (productsNext ? true : false));

  const fetchMoreProducts = () => {
    if (productsNext) {
      dispatch(fetchMoreProductsByUniqueLinkCode(productsNext));
    }
  };
  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (productsNext) {
      setHasMoreProducts(true);
    } else {
      setHasMoreProducts(false);
    }
  }, [productsNext]);

  //Side effect to fetch products on first mount
  useEffect(() => {
    dispatch(fetchProductsByUniqueLinkCode({ unique_link_code: uniqueLinkCode }))
      .then((action) => {
        if (action.payload) {
        }
      })
      .catch((err) => {
        toast.error("Failed to fetch products!");
      });
  }, [dispatch]);

  //Check for products if it exist or not
  useEffect(() => {
    if (products?.length > 0) {
      setProductList(products);
    }
  }, [products]);

  // const handleOrderButton = () => {
  //   alert("handleOrderButton was clicked");
  //   console.log({
  //     cartItems,
  //     deliveryType,
  //     paymentMethod,
  //     slot: {
  //       from: formatTime(slot.from),
  //       to: formatTime(slot.to),
  //     },
  //   });
  // };

  // const handleFavToggle = (index) => {
  //   const updatedProducts = productList.map((product, i) => {
  //     if (i === index) {
  //       return { ...product, isFav: !product.isFav };
  //     }
  //     return product;
  //   });
  //   setProductList(updatedProducts);
  // };

  const filteredProducts = productList
    .filter((product) => {
      if (sortType === "inStock") return product.remaining_quantity > 0;
      if (sortType === "outOfStock") return product.remaining_quantity === 0;
      // if (sortType === "favorites") return product.isFav;
      return true;
    })
    .sort((a, b) => {
      if (sortType === "lowToHigh") return a.lowest_rate_batch.sales_price - b.lowest_rate_batch.sales_price;
      if (sortType === "highToLow") return b.lowest_rate_batch.sales_price - a.lowest_rate_batch.sales_price;
      return 0;
    });

  //Check if product in cart item cache
  const checkProductInCart = (product) => {
    return cartItemsCache.findIndex((cacheProduct) => cacheProduct.id === product.id) >= 0;
  };

  return (
    <div className="p-4">
      <div className="flex flex-col xs:flex-row justify-between gap-[10px] items-end xs:items-center mb-[20px] xs:px-[20px]">
        <span className="text-lg order-2 xs:order-1 w-full xs:w-fit text-center mt-[20px] xs:mt-[0]">
          Total {filteredProducts.length} Products Available
        </span>
        <select
          className="border order-1 xs:order-2 border-black bg-white px-[20px] py-[10px] w-fit rounded-full"
          value={sortType}
          onChange={(e) => setSortType(e.target.value)}
        >
          <option value="all">All</option>
          <option value="lowToHigh">Price: Low to High</option>
          <option value="highToLow">Price: High to Low</option>
          <option value="inStock">Product: In Stock</option>
          <option value="outOfStock">Product: Out of Stock</option>
          {/* <option value="favorites">Product: Favorites Only</option> */}
        </select>
      </div>
      <InfiniteScroll
        dataLength={products.length}
        next={fetchMoreProducts}
        hasMore={hasMoreProducts}
        height={"80vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
      >
        <div className="lg:px-[20px] grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {filteredProducts.map((product, index) => (
            <ProductCard key={index} product={product} inCart={checkProductInCart(product)} />
          ))}
        </div>
      </InfiniteScroll>

      {/* Go To cart button  */}
      <button
        className="px-[20px] py-[10px] text-white bg-black rounded-full fixed bottom-[50px] left-[50%]"
        onClick={() => navigate(`/${uniqueLinkCode}/checkout`)}
      >
        Go To Cart
      </button>
    </div>
  );
};

export default Home;
