import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Suspense } from "react";
import PublicRoute from "./components/PublicRoutes";
import Login from "./components/Login/Login";
import LoadScreen from "./components/LoadScreen";
import Desktopapp from "./components/DesktopApp";
import LinkNotFound from "./components/LinkNotFound";

const Approuter = () => {
  return (
    <Router>
      <Routes>
        <Route
          index
          path="/"
          element={
            <Suspense fallback={<LoadScreen />}>
              <LinkNotFound uniqueLinkCode={""} />
            </Suspense>
          }
        />
        <Route
          index
          path="/:uniqueLinkCode/*"
          element={
            <Suspense fallback={<LoadScreen />}>
              <Desktopapp />
            </Suspense>
          }
        />

        <Route element={<PublicRoute />}>
          <Route
            path="/:uniqueLinkCode/login"
            element={
              <Suspense fallback={<LoadScreen />}>
                <Login />
              </Suspense>
            }
          />
        </Route>
        {/* <Route element={<PublicRoute />}>
          <Route
            path="/forgetPassword"
            element={
              <Suspense fallback={<LoadScreen />}>
                <ForgetPassword />
              </Suspense>
            }
          />
        </Route> */}
      </Routes>
    </Router>
  );
};

export default Approuter;
