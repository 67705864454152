import React from "react";
// import './Transaction.scss';
// import TopSection from "./TransactionComponent/TopSection";
import InvoiceContainer from "./InvoiceComponent/InvoiceContainer";

const Invoices = () => {
  return (
    <div className="p-[10px]">
      <div className="border-2 border-neutral-200 rounded-[20px] p-[20px]">
        <InvoiceContainer />
      </div>
    </div>
  );
};

export default Invoices;
