import React from "react";

const LoadScreen = () => {
  return (
    <div className="">
      <div className="">loading...</div>
    </div>
  );
};

export default LoadScreen;
