import { format } from "date-fns";

//Funtion to change date format to yyyy-mm-dd format
export const changeDateFormat = (unformattedDate) => {
  return format(unformattedDate, "yyyy-MM-dd");
};

export const humanDateFormat = (unformattedDate) => {
  return format(unformattedDate, "dd/MM/yyyy");
};
