import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import Timer from "../OnlineOrderComponents/Timer";

//UI components
import DetailContainer from "../../MicroComponents/DetailContainer";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsByID } from "../../../reducers/Slices/orderSlice";

function OrderDetailsMobile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  // const [selectedOrder, setSelectedOrder] = useState({});

  function convertTimestamp(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Options for formatting the date and time
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, // For 12-hour format, set to false for 24-hour format
    };

    // Convert the date to a string with the specified options
    return date.toLocaleString("en-US", options);
  }

  const orderDetails = useSelector((state) => state.order.orderDetails);

  // const [timerEnd, setTimerEnd] = useState(true);

  useEffect(() => {
    // setTimerEnd(selectedOrder?.order_details?.timerStatus);
    dispatch(getOrderDetailsByID(id));
  }, [id, dispatch]);

  //Fake order data
  // const orders = [
  //   {
  //     id: 1,
  //     order_details: {
  //       order_status: "Pending",
  //       ordered_on: "20/10/2024",
  //       total_amount: "₹20,000",
  //       type: "Delivery",
  //       store_id: 1,
  //       name: "Imagine LTD",
  //       contact_number: "+91-8290194551",
  //       address: "357 Maplewood Drive, Greenfield, IL 62704, USA",
  //       payment_method: "Cash on Delivery",
  //       slot_selected: "7 AM to 7 PM",
  //       timerStatus: true,
  //       ordered_products: [
  //         {
  //           product_name: "Oneplus 6T",
  //           product_brand: "Oneplus",
  //           product_batch: "IQT28M",
  //           quantity: 10,
  //         },
  //         {
  //           product_name: "Iphone 13",
  //           product_brand: "Apple",
  //           product_batch: "PMT28M",
  //           quantity: 24,
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 2,
  //     order_details: {
  //       order_status: "Fulfilled",
  //       ordered_on: "20/10/2024",
  //       total_amount: "₹20,000",
  //       type: "Pick Up",
  //       store_id: 10,
  //       name: "Moritus LTD",
  //       contact_number: "+91-8290194551",
  //       address: "357 Maplewood Drive, Greenfield, IL 62704, USA",
  //       payment_method: "UPI",
  //       slot_selected: "7 AM to 7 PM",
  //       timerStatus: false,
  //       ordered_products: [
  //         {
  //           product_name: "Oneplus 6T",
  //           product_brand: "Oneplus",
  //           product_batch: "IQT28M",
  //           quantity: 10,
  //         },
  //         {
  //           product_name: "Iphone 13",
  //           product_brand: "Apple",
  //           product_batch: "PMT28M",
  //           quantity: 24,
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 3,
  //     order_details: {
  //       order_status: "Cancelled",
  //       ordered_on: "20/10/2024",
  //       total_amount: "₹20,000",
  //       type: "Pick Up",
  //       store_id: 2,
  //       name: "Malobar LTD",
  //       contact_number: "+91-8230194551",
  //       address: "357 Maplewood Drive, Greenfield, IL 62704, USA",
  //       payment_method: "Cash On Delivery",
  //       slot_selected: "6 AM to 4 PM",
  //       timerStatus: true,
  //       ordered_products: [
  //         {
  //           product_name: "Oneplus 6T",
  //           product_brand: "Oneplus",
  //           product_batch: "IQT28M",
  //           quantity: 10,
  //         },
  //         {
  //           product_name: "Iphone 13",
  //           product_brand: "Apple",
  //           product_batch: "PMT28M",
  //           quantity: 24,
  //         },
  //       ],
  //     },
  //   },
  // ];

  // useEffect(() => {
  //   // setSelectedOrder(orders.find((order) => order.id === parseInt(id)));
  // }, [id]);

  // useEffect(() => {
  //   setTimerEnd(selectedOrder?.order_details?.timerStatus);
  // }, [selectedOrder]);

  const orderStatusColor = (orderStatus) => {
    if (orderStatus === "Fulfilled" || orderStatus === "fulfilled") {
      return "text-green-500";
    } else if (orderStatus === "Cancelled" || orderStatus === "cancelled") {
      return "text-neutral-500";
    } else if (orderStatus === "Pending" || orderStatus === "pending") {
      return "text-red-500";
    } else if (orderStatus === "Pickup" || orderStatus === "pickup") {
      return "text-green-500";
    } else if (orderStatus === "Delivered" || orderStatus === "delivered") {
      return "text-green-500";
    }
  };

  return (
    <div>
      {/* selected order and order details section */}
      <div className="border-b-2 border-neutral-200 pb-[20px] mb-[10px]">
        {/* Selected order description */}
        <div className="h-[8.3vh] border-b-2 border-neutral-200 flex justify-between px-[20px] items-center">
          <h3 className="text-2xl text-neutral-500">{`Order ${orderDetails?.id}`}</h3>
          <span className="flex gap-[10px] items-center">
            Status:
            <span className={orderStatusColor(orderDetails?.status)}>{orderDetails?.status}</span>
          </span>
          {/* {!timerEnd && (
            <span className="flex gap-[10px] items-center">
              Status:
              <span className={orderStatusColor(selectedOrder?.order_details?.order_status)}>
                {selectedOrder?.order_details?.order_status}
              </span>
            </span>
          )}
          {timerEnd && (
            <button
              className="text-red-500 border rounded-full border-red-500 px-[20px] py-[10px]"
              onClick={() => alert("cancel order was clicked!")}
            >
              Cancel Order -{" "}
              <Timer
                orderId={selectedOrder?.id}
                setTimerEnd={setTimerEnd}
                timerStatus={selectedOrder?.order_details?.timerStatus}
              />
            </button>
          )} */}
        </div>
        {/* Order details section */}
        <div>
          <span className="text-neutral-500 py-[20px] text-center w-full block">Order Details</span>
          <div className="flex flex-col px-[10px] gap-[10px]">
            <DetailContainer title="Order type" value={orderDetails?.order_type} />
            {/* <DetailContainer title="Contact Number" value={selectedOrder?.order_details?.contact_number} /> */}
            {/* <DetailContainer title="Address" value={selectedOrder?.order_details?.address} /> */}
            {orderDetails?.status === "Fulfilled" && (
              <DetailContainer title="Payment Method" value={orderDetails?.payment_option?.payment_method} />
            )}
            <DetailContainer title="Total Amount" value={orderDetails?.total_amount} />
            {orderDetails?.status === "Fulfilled" && (
              <DetailContainer title="Ordered On" value={convertTimestamp(orderDetails?.invoice?.order_date_time)} />
            )}
            <DetailContainer title="Slot Selected" value={convertTimestamp(orderDetails?.time_slot)} />
          </div>
        </div>
      </div>
      {/* ordered products sections */}
      <div>
        {/* ordered products */}
        <div className="h-[8.3vh] pb-[10px] border-b-2 border-neutral-200 text-xl flex items-center justify-center gap-[10px]">
          Total Ordered Products:
          <span className="text-neutral-500">{orderDetails?.items?.length}</span>
        </div>
        {/* ordered products list */}
        <div className="px-[10px] flex flex-col gap-[10px] pb-[10px]">
          {orderDetails?.items?.length > 0 &&
            orderDetails?.items?.map((item, index) => (
              <div
                key={`${item?.product?.product_name}${index}`}
                className="flex flex-col border-2 border-neutral-200 rounded-[20px] p-[20px] first:mt-[20px]"
              >
                <span className="text-xl block pb-[10px]">{item?.product?.product_name}</span>
                <span>
                  Brand: <span className="text-neutral-500">{item?.product?.brand}</span>
                </span>
                {/* <span>
                  Batch: <span className="text-neutral-500">{product?.product_batch}</span>
                </span> */}
                <span>
                  Quantity: <span className="text-neutral-500">{item?.quantity}</span>
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default OrderDetailsMobile;
