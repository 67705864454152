import React, { useEffect, useState } from "react";
import ProductCard from "../Home/HomeComponents/ProductCard";
import {
  addToCart,
  fetchMoreSearchProductsByUniqueLinkCode,
  fetchSearchProductsByUniqueLinkCode,
  removeFromCart,
} from "../../reducers/Slices/productSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { searchSectionFunction } from "../../reducers/Slices/appSlice";

function SearchSection() {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => state.app.searchSection.value);

  const [sortType, setSortType] = useState("all");
  const [productList, setProductList] = useState([]);
  const { uniqueLinkCode } = useParams();

  const products = useSelector((state) => state.product.searchedProducts.data);
  const productsNext = useSelector((state) => state.product.searchedProducts.next);

  const [hasMoreProducts, setHasMoreProducts] = useState(() => (productsNext ? true : false));

  const fetchMoreProducts = () => {
    if (productsNext) {
      dispatch(fetchMoreSearchProductsByUniqueLinkCode(productsNext));
    }
  };
  //Cart items cache from redux store
  const cartItemsCache = useSelector((state) => state.product.cartItems);

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (productsNext) {
      setHasMoreProducts(true);
    } else {
      setHasMoreProducts(false);
    }
  }, [productsNext]);

  //Side effect to fetch products on first mount
  useEffect(() => {
    dispatch(
      fetchSearchProductsByUniqueLinkCode({ unique_link_code: uniqueLinkCode, stringQuery: `?search=${searchValue}` })
    )
      .then((action) => {
        if (action.payload) {
        }
      })
      .catch((err) => {
        toast.error("Failed to fetch products!");
      });
  }, [dispatch, searchValue]);

  //Check for products if it exist or not
  useEffect(() => {
    if (products?.length > 0) {
      setProductList(products);
    }
  }, [products]);

  // const handleFavToggle = (index) => {
  //   const updatedProducts = productList.map((product, i) => {
  //     if (i === index) {
  //       return { ...product, isFav: !product.isFav };
  //     }
  //     return product;
  //   });
  //   setProductList(updatedProducts);
  // };

  const filteredProducts = productList
    .filter((product) => {
      if (sortType === "inStock") return product.remaining_quantity > 0;
      if (sortType === "outOfStock") return product.remaining_quantity === 0;
      // if (sortType === "favorites") return product.isFav;
      return true;
    })
    .sort((a, b) => {
      if (sortType === "lowToHigh") return a.lowest_rate_batch.sales_price - b.lowest_rate_batch.sales_price;
      if (sortType === "highToLow") return b.lowest_rate_batch.sales_price - a.lowest_rate_batch.sales_price;
      return 0;
    });

  const closeSearchSection = () => {
    dispatch(searchSectionFunction(""));
  };

  //Check if product in cart item cache
  const checkProductInCart = (product) => {
    return cartItemsCache.findIndex((cacheProduct) => cacheProduct.id === product.id) >= 0;
  };

  return (
    <div className="p-4">
      <div className="flex flex-col xs:flex-row justify-between gap-[10px] items-end xs:items-center mb-[20px] xs:px-[20px]">
        <span className="text-lg order-2 xs:order-1 w-full xs:w-fit text-center mt-[20px] xs:mt-[0]">
          Total {filteredProducts.length} Products Available
        </span>
        <select
          className="border order-1 xs:order-2 border-black bg-white px-[20px] py-[10px] w-fit rounded-full"
          value={sortType}
          onChange={(e) => setSortType(e.target.value)}
        >
          <option value="all">All</option>
          <option value="lowToHigh">Price: Low to High</option>
          <option value="highToLow">Price: High to Low</option>
          <option value="inStock">Product: In Stock</option>
          <option value="outOfStock">Product: Out of Stock</option>
          {/* <option value="favorites">Product: Favorites Only</option> */}
        </select>
      </div>
      <InfiniteScroll
        dataLength={products.length}
        next={fetchMoreProducts}
        hasMore={hasMoreProducts}
        height={"80vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
      >
        <div className="lg:px-[20px] grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {filteredProducts.map((product, index) => (
            <ProductCard key={index} product={product} inCart={checkProductInCart(product)} />
          ))}
        </div>
      </InfiniteScroll>

      {/* Close Search Button */}
      <div className="hidden md:flex absolute bottom-0 left-0 w-full h-[20vh] items-center justify-center">
        <button onClick={() => closeSearchSection()} className="px-[20px] py-[20px] bg-black rounded-full text-white">
          Close Search Section
        </button>
      </div>
    </div>
  );
}

export default SearchSection;
