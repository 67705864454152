export const TimeStampPastDate = (stamp) => {
  if (!stamp) {
    return;
  }

  const currentDateStamp = new Date().getTime();
  const expireAtStamp = parseInt(stamp);

  if (currentDateStamp > expireAtStamp) {
    return true;
  }
  return false;
};
