// import logo from "./logo.svg";
// import "./App.css";
import Approuter from "./AppRouter";
import { store } from "./reducers/Store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Provider store={store}>
      <Approuter />
      <Toaster position="bottom-right" />
    </Provider>
  );
}

export default App;
